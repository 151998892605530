<template>
  <div class="wrapper">
    <div class="input-group">
      <input
        :id="schema.model"
        type="text"
        class="form-control"
        :value="isValid ? value: date"
        @input="onFieldChanged"
        :class="schema.fieldClasses"
        @change="schema.onChange || null"
        :disabled="disabled"
        :accept="schema.accept"
        :alt="schema.alt"
        :autocomplete="schema.autocomplete"
        :checked="schema.checked"
        :dirname="schema.dirname"
        :formaction="schema.formaction"
        :formenctype="schema.formenctype"
        :formmethod="schema.formmethod"
        :formnovalidate="schema.formnovalidate"
        :formtarget="schema.formtarget"
        :height="schema.height"
        :list="schema.list"
        :max="schema.max"
        :maxlength="schema.maxlength"
        :min="schema.min"
        :minlength="schema.minlength"
        :multiple="schema.multiple"
        :name="schema.inputName"
        :pattern="schema.pattern"
        :placeholder="schema.placeholder || schema.format"
        :readonly="schema.readonly"
        :required="schema.required"
        :size="schema.size"
        :src="schema.src"
        :step="schema.step"
        :width="schema.width"
        :files="schema.files"
        v-attributes="'input'"
      />
      <div class="input-group-append">
        <b-form-datepicker
          :value="value"
          @input="onDatePicked"
          button-only
          right
          :disabled="disabled"
          :readonly="schema.readonly"
          locale="en-US"
        ></b-form-datepicker>
      </div>
    </div>
    <b-alert class="d-block mt-1 py-1" v-if="!isValid" variant="warning" show>
      Choose a valid date (YYYY-MM-DD)
    </b-alert>
  </div>
</template>

<script>
  import {abstractField} from 'vue-form-generator'
  import {isValidDate} from '@hc/graphql'
  export default {
    mixins: [ abstractField ],
    data() {
      return {
        isValid: true,
        date: null
      }
    },
    watch: {'model.id': 'onIdChanged'},
    methods: {
      onFieldChanged($event) {
        const date = $event.target.value
        this.isValid = isValidDate(date)
        if (!this.isValid) return this.date = date
        this.value = date
      },
      onDatePicked( date ) {
        this.isValid = isValidDate(date)
        this.value = date
        this.$emit('input', date)
      },
      onIdChanged() {
        this.isValid = isValidDate(this.model[this.schema.model])
        this.value = this.model[this.schema.model]
      }
    }
  }
</script>
