import {Graphql} from '@hc/graphql'
import {Budget, ChangeStatusAction, Invoice, Contract, Project, Office} from '@wndl/api'
import FileSaver from 'file-saver'
import wndlApi from '@wndl/api'
import gql from 'graphql-tag'
import {useUiStore} from "@hc/ui";

Graphql.collection('project')
  .registerFormAction('issuer_address', {
    label: "Address book",
    onclick: async (model, field, event, component) => {
      const ui = useUiStore()
      const office: Office = await wndlApi.crudCollection('office').find(model.office_id)
      const result = await ui.openModal({
        title: 'Select Issuer Address',
        component: 'CompanyAddressModal',
        params: {
          company_id: office.company_id
        }
      })
      if(result){
        model.issuer_address = result.formatted_address
        component.validate()
      }
    }
  })

Graphql.collection('budget')
  .registerFormAction('issuer_address', {
    label: "Address book",
    onclick: async (model, field, event, component) => {
      const ui = useUiStore()
      const office: Office = await wndlApi.crudCollection('office').find(model.office_id || model.project.office_id)
      const result = await ui.openModal({
        title: 'Select Issuer Address',
        component: 'CompanyAddressModal',
        params: {
          company_id: office.company_id
        }
      })
      if(result){
        model.issuer_address = result.formatted_address
        component.validate()
      }
    }
  })

Graphql.collection('contract')
  .registerWorkflowAction(new ChangeStatusAction({
    name: 'download',
    label: 'Download',
    dialog: 'ContractDownloadModal',
    confirmable: false,
    handler: async (contract: Contract, params) => {
      const result = await wndlApi.apollo.query({
        query: gql` query($id: Int!, , $params: contract_download_params!) {
          result: contract_download(id: $id, params: $params)
        }`,
        variables: {
          id: contract.id,
          params
        }
      })
      if (!result.data) throw new Error(result.errors[0].message)
      const byteCharacters = atob(result.data.result)
      const byteNumbers = new Array(byteCharacters.length)
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: "application/pdf" })

      const [budget] = await wndlApi.crudCollection('budget').list({
        filter: {contracts: {id: {_eq: contract.id}}},
        includeDefaultJoins: true
      })

      FileSaver.saveAs(blob, `contract.${budget.project.name}.${budget.name}.pdf`)
    }
  }))
  .registerFormAction('issuer_address', {
    label: "Address book",
    onclick: async (model, field, event, component) => {
      const ui = useUiStore()
      const result = await ui.openModal({
        title: 'Select Issuer Address',
        component: 'CompanyAddressModal',
        params: {
          company_id: model.budget.project.office.company_id
        }
      })
      if(result){
        model.issuer_address = result.formatted_address
        component.validate()
      }
    }
  })
  .registerFormAction('recipient_address', {
    label: "Address book",
    onclick: async (model, field, event, component) => {
      const ui = useUiStore()
      const result = await ui.openModal({
        title: 'Select Recipient Address',
        component: 'CompanyAddressModal',
        params: {
          company_id:model.recipient_id
        }
      })
      if(result){
        model.recipient_address = result.formatted_address
        component.validate()
      }
    }
  })

Graphql.collection('invoice')
  .registerWorkflowAction(new ChangeStatusAction({
    name: 'download',
    label: 'Download',
    dialog: 'InvoiceDownloadModal',
    handler: async (invoice: Invoice, params) => {
      const filename = [
        'invoice',
        invoice.invoice_number ? invoice.invoice_number : `draft-${invoice.id}`,
        //invoice.reference.replace(/\s+/g, '-'),
        'pdf'
      ].join('.')
      const result = await wndlApi.apollo.query({
        query: gql` query($id: Int!, $params: invoice_download_params) {
          result: invoice_download(id: $id, params: $params)
        }`,
        variables: {
          id: invoice.id,
          params: params
        }
      })

      const byteCharacters = atob(result.data.result)
      const byteNumbers = new Array(byteCharacters.length)
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: "application/pdf" })

      FileSaver.saveAs(blob, filename)
    }
  }))
  .registerFormAction('issuer_address', {
    label: "Address book",
    classes: "issuer-address-book",
    onclick: async (model, field, event, component) => {
      const ui = useUiStore()
      const project: Project = await wndlApi.crudCollection('project').find(model.project_id)
      const result = await ui.openModal({
        title: 'Select Issuer Address',
        component: 'CompanyAddressModal',
        params: {
          company_id: project.office.company_id
        }
      })
      if(result){
        model.issuer_address = result.formatted_address
        component.validate()
      }
    }
  })
  .registerFormAction('recipient_address', {
    label: "Address book",
    classes: "recipient-address-book",
    onclick: async (model, field, event, component) => {
      const ui = useUiStore()
      const result = await ui.openModal({
        title: 'Select Recipient Address',
        component: 'CompanyAddressModal',
        params: {
          company_id: model.recipient_id
        }
      })
      if(result){
        model.recipient_address = result.formatted_address
        component.validate()
      }
    }
  })
  .registerFormAction('tax_address', {
    label: "Address book",
    classes: "tax-address-book",
    onclick: async (model, field, event, component) => {
      const ui = useUiStore()
      const result = await ui.openModal({
        title: 'Select Tax Address',
        component: 'CompanyAddressModal',
        params: {
          company_id: model.recipient_id
        }
      })
      if(result){
        model.tax_address = result.formatted_address
        component.validate()
      }
    }
  })

Graphql.collection('budget')
  .registerWorkflowAction(new ChangeStatusAction({
    name: 'download',
    group: 'Download',
    dialog: 'BudgetDownloadModal',
    label: 'Download',
    handler: async (budget: Budget, params) => {
      const project = await Graphql.collection('project').find(budget.project_id)
      const result = await wndlApi.apollo.query({
        query: gql` query($id: Int!, $params: budget_download_params) {
          result: budget_download(id: $id, params: $params)
        }`,
        variables: {
          id: budget.id,
          params: params
        }
      })
      const byteCharacters = atob(result.data.result)
      const byteNumbers = new Array(byteCharacters.length)
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: "application/pdf" })

      if(params.template == 'excel'){
        FileSaver.saveAs(blob, `budget.${project.name}.${budget.name}.xlsx`)
      } else {
        FileSaver.saveAs(blob, `budget.${project.name}.${budget.name}.pdf`)
      }
    }
  }))
