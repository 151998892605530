<template>
  <b-alert show variant="warning">
    <strong>Not editable here!</strong>
  </b-alert>
</template>

<script>
  // NOTE: This editor is not used anymore, the fields are hidden as we are handling the invoice positions
  //       now we are handling it in the form fields slow of the edit page.
  import { abstractField } from 'vue-form-generator'
  export default {
    mixins: [ abstractField ],
  }
</script>
