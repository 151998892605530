import Vue from 'vue'
import App from './App.vue'
import router from './router'
import wndlApi, {constants} from '@wndl/api'
import {AppBootParams, bootstrapApp} from '@hc/ui'
import {vueContext} from '@hc/ui/vue'
import {auth, Graphql} from "@hc/graphql"
import toastr from "toastr";
import Datepicker from "@hc/ui/crud/editors/Datepicker.vue"
import Timepicker from "@hc/ui/crud/editors/Timepicker.vue"
import { Chrome } from 'vue-color'
import VueApexChart from 'vue-apexcharts/dist/vue-apexcharts'
import DateRangePicker from 'vue2-daterange-picker'
import VueMoment from 'vue-moment'
import InvoicePositionsEditor from "@/invoice/form/InvoicePositions.vue"

import moment from 'moment'
import 'moment/locale/de'

import '@hc/ui/app/store'
import '@hc/ui/vue/ag_grid'
import './crud'

toastr.options.positionClass = 'toast-bottom-left'
toastr.options.timeOut = 3500

/**
 *   // rollbar and error handline
 *   wndlApi.config.rollbar.accessToken = app.config.rollbar.accessToken
 *   wndlApi.config.rollbar.enabled = app.config.rollbar.enabled
 *   window['apiReady'] = false
 *   if (window['Cypress']) {
 *     window['wndl'] = {
 *       app, crudAuth, api: wndlApi
 *     }
 *     window['apiReady'] = true
 *   }
 */

export const VuePlugin = {
  install() {
    Vue.prototype.$env = process.env.NODE_ENV || 'development'
    Vue.prototype.$prod = Vue.prototype.$env == 'production'
    Vue.prototype.$dev = Vue.prototype.$env == 'development'
    Vue.prototype.$schema = Graphql
    Vue.prototype.$crud = wndlApi.crud
    Vue.prototype.$config = wndlApi.config
    Vue.prototype.$auth =  auth.state
    Vue.prototype.$toastr  = toastr
    Vue.prototype.$const = constants
  }
}

vueContext.addPlugins([
  VuePlugin
])

vueContext.addComponents({
  Datepicker,
  Timepicker,
  ColorPicker:  Chrome,
  VueApexChart,
  FieldInvoicePositions: InvoicePositionsEditor,
  DateRangePicker,
})

vueContext.importComponents(import.meta.glob('../../../lib/hc-ui/**/components/**/*.vue', {eager: false}))
vueContext.importVfgFields(import.meta.glob('../../../lib/hc-ui/**/editors/**/*.vue', {eager: false}))
vueContext.importComponents(import.meta.glob('./**/components/**/*.vue', {eager: false}))

const params: AppBootParams = {
  appName: 'wndl',
  scope: 'app',
  api: wndlApi,
  root: App,
  router,
  configUrl: window.location.host == 'localhost:9000' ? 'http://localhost:9060/config.json' : '/config.json',
  beforeBoot: async (vue) => {
    Vue.use(VueMoment, {moment})
  }
}

bootstrapApp(params).catch((e) => {
  console.error("Bootstrap Error", e)
})
