<template>
  <div id="app">
    <template v-if="app.startup">
      <!--<b-spinner small class="m-2" type="grow" />-->
    </template>
    <template v-else>
      <app-navbar v-if="navbar"></app-navbar>
      <b-alert variant="info" :show="app.newVersionAvailable">
        <h3 class="m-0">A new Wonderland version is available!</h3>
        <p>
          Please make sure to submit any unsaved data and
          <b-button size="sm" variant="success" @click="app.reload">reload</b-button>
          the browser window as soon as possible as you might get unexpected errors otherwise.
        </p>
      </b-alert>
      <router-view :key="$route.fullPath"/>
      <app-modals />
      <auth-modal />
    </template>
  </div>
</template>

<script>
  import {useAppStore} from "@hc/ui";
  export default {
    provide: {
      APP_NAVBAR_HOME_ICON: 'home',
    },
    setup(){
      const app = useAppStore()
      return {app}
    },
    computed: {
      navbar(){
        if(this.$route.meta.hasOwnProperty('navbar')) return this.$route.meta.navbar
        return true
      },
    },
  }
</script>
