import Vue from 'vue'
import Router from 'vue-router'
import {Graphql} from '@hc/graphql'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes: [
    {
      name: 'home',
      path: '/',
      meta: { noAuth: true },
      component: () => import(/* webpackChunkName: "home" */ './home/pages/HomePage.vue'),
    },
    {
      name: 'history',
      path: '/history',
      component: () => import(/* webpackChunkName: "home" */ '@hc/ui/crud/pages/HistoryPage.vue'),
    },
    {
      name: 'diamantStatus',
      path: '/diamant/status',
      meta: { navbar: true },
      component: () => import(/* webpackChunkName: "home" */ './diamant/pages/DiamantStatus.vue'),
    },
    {
      name: 'diamantDashboard',
      path: '/diamant/dashboard',
      meta: { navbar: true },
      component: () => import(/* webpackChunkName: "home" */ './diamant/pages/DiamantDashboard.vue'),
    },
    {
      name: 'jobQueueList',
      path: '/queue/status',
      meta: { navbar: true },
      component: () => import(/* webpackChunkName: "home" */ '@hc/ui/job/pages/JobQueuelist.vue'),
    },
    {
      name: 'paymentStatus',
      path: '/payment/status',
      meta: { navbar: true },
      component: () => import(/* webpackChunkName: "home" */ '@hc/ui/payment/pages/PaymentStatus.vue'),
    },
    {
      name: 'service.incomingInvoices',
      path: '/accounting/service-incoming-invoices',
      meta: { navbar: true },
      component: () => import(/* webpackChunkName: "home" */ './service/pages/ServiceIncomingInvoiceDashboard.vue'),
    },
    {
      name: 'rawEditor',
      path: '/util/raw-editor',
      meta: { navbar: true },
      component: () => import(/* webpackChunkName: "home" */ '@hc/ui/schema/pages/SchemaRawEditor.vue'),
    },
    {
      path: "/auth/callback/:provider",
      name: "authcallback",
      meta: { noAuth: true , navbar: false},
      component: () => import(/* webpackChunkName: "home" */ '@hc/ui/auth/pages/AuthCallBack.vue') ,
    },
    {
      path: '/user',
      component: () => import(/* webpackChunkName: "user" */ './user/pages/UserPage.vue'),
      children: [
        {
          name: 'user.home',
          path: '',
          component: () => import(/* webpackChunkName: "user" */ './user/pages/UserHomePage.vue'),
        },
      ]
    },
    {
      name: 'calendar',
      path: '/calendar',
      component: () => import(/* webpackChunkName: "project" */ './project/pages/ProjectCalendarPage.vue'),
    },
    {
      name: 'project.explorer',
      path: '/data/project/explorer',
      component: () => import(/* webpackChunkName: "project" */ './project/pages/ProjectExplorerPage.vue'),
    },
    {
      name: 'project.tax.explorer',
      path: '/data/project/explorer/tax',
      component: () => import(/* webpackChunkName: "project" */ './project/pages/ProjectTaxExplorerPage.vue'),
    },
    {
      name: 'budget.editor',
      path: '/data/budget/:id/budget-editor/:activeServiceId?',
      props: (route) => { return {route} },
      component: () => import(/* webpackChunkName: "home" */ './budget/pages/BudgetEditorPage.vue'),
    },
    {
      name: 'accessManager',
      path: '/access',
      props: true,
      component: () => import(/* webpackChunkName: "home" */ './access/pages/AccessManager.vue'),
    },
    {
      name: 'ownerCompanySettings',
      path: '/owner-company-settings',
      props: true,
      component: () => import(/* webpackChunkName: "home" */ './company/pages/CompanyOwnerSettings.vue'),
    },
    {
      name: 'authorizeServices',
      path: '/authorize-services',
      props: true,
      component: () => import(/* webpackChunkName: "home" */ './external/AuthorizeServices.vue'),
    },
    {
      path: '/data/:collection',
      props: (route) => { return {route} },
      component: () => import(/* webpackChunkName: "crud" */ '@hc/ui/crud/pages/BasePage.vue'),
      beforeEnter: (to, from, next) => {
        const collection = to.params['collection']
        if(Graphql.collections[collection]){
          next()
        } else {
          console.error("Unknown crud configuration:", collection)
        }
      },
      children: [
        {
          name: 'crud.list',
          path: '',
          props: (route) => { return {route} },
          component: () => import(/* webpackChunkName: "crud" */ '@hc/ui/crud/pages/ListPage.vue'),
        },
        {
          name: 'crud.create',
          path: 'create',
          props: (route) => { return {route} },
          component: () => import(/* webpackChunkName: "crud" */ '@hc/ui/crud/pages/CreatePage.vue'),
        },
        {
          name: 'crud.object',
          path: ':id',
          props: (route) => { return {route} },
          component: () => import(/* webpackChunkName: "crud" */ '@hc/ui/crud/pages/ViewPage.vue'),
        },
        {
          name: 'crud.edit',
          path: ':id/edit',
          props: (route) => { return {route} },
          component: () => import(/* webpackChunkName: "crud" */ '@hc/ui/crud/pages/EditPage.vue'),
        },
        {
          name: 'crud.relation',
          path: ':id/:relation',
          props: (route) => { return {route} },
          component: () => import(/* webpackChunkName: "crud" */ '@hc/ui/crud/pages/RelationPage.vue'),
        },
        {
          name: 'crud.relation.create',
          path: ':id/:relation/create',
          props: (route) => { return {route} },
          component: () => import(/* webpackChunkName: "crud" */ '@hc/ui/crud/pages/RelationCreatePage.vue'),
        },
        {
          name: 'crud.relation.edit',
          path: ':id/:relation/:itemId/edit',
          props: (route) => { return {route} },
          component: () => import(/* webpackChunkName: "crud" */ '@hc/ui/crud/pages/RelationEditPage.vue'),
        },
        {
          name: 'crud.relation.object',
          path: ':id/:relation/:itemId',
          props: (route) => { return {route} },
          component: () => import(/* webpackChunkName: "crud" */ '@hc/ui/crud/pages/RelationViewPage.vue'),
        },
      ],
    },
    { path: '*', component: () => import(/* webpackChunkName: "error" */ './error/pages/ErrorNotFoundPage.vue') }
  ],
})
