import {AgGridVue} from '@ag-grid-community/vue'
import {ModuleRegistry, AllModules} from '@ag-grid-enterprise/all-modules'
import {AllCommunityModules} from '@ag-grid-community/all-modules'
import {LicenseManager} from "@ag-grid-enterprise/core"
import {vueContext} from "../vue";

ModuleRegistry.registerModules(AllModules)
ModuleRegistry.registerModules(AllCommunityModules)

LicenseManager.setLicenseKey("CompanyName=Time Change GmbH,LicensedGroup=Time Change 1,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-007319,ExpiryDate=12_March_2021_[v2]_MTYxNTUwNzIwMDAwMA==817b9110539d5d4b9beae8161b6f2ca9");

vueContext.addComponents({
  AgGridVue
})