<template>
  <div class="wrapper">
    <div class="input-group">
      <input
        :id="schema.model"
        type="text"
        class="form-control"
        :value="isValid ? formattedValue: time"
        @input="onFieldChanged"
        :class="schema.fieldClasses"
        @change="schema.onChange || null"
        :disabled="disabled"
        :accept="schema.accept"
        :alt="schema.alt"
        :autocomplete="schema.autocomplete"
        :checked="schema.checked"
        :dirname="schema.dirname"
        :formaction="schema.formaction"
        :formenctype="schema.formenctype"
        :formmethod="schema.formmethod"
        :formnovalidate="schema.formnovalidate"
        :formtarget="schema.formtarget"
        :height="schema.height"
        :list="schema.list"
        :max="schema.max"
        :maxlength="schema.maxlength"
        :min="schema.min"
        :minlength="schema.minlength"
        :multiple="schema.multiple"
        :name="schema.inputName"
        :pattern="schema.pattern"
        :placeholder="schema.placeholder || schema.format"
        :readonly="schema.readonly"
        :required="schema.required"
        :size="schema.size"
        :src="schema.src"
        :step="schema.step"
        :width="schema.width"
        :files="schema.files"
        v-attributes="'input'"
      />
      <div class="input-group-append">
        <b-form-timepicker
          :disabled="disabled"
          :value="formattedValue || time"
          :hour12="false"
          :show-seconds="false"
          @input="onTimePicked"
          button-only
          right
        ></b-form-timepicker>
      </div>
    </div>
    <b-alert class="mt-1 py-1" v-if="!isValid" variant="warning" show>
      Choose a valid time (HH:mm)
    </b-alert>
  </div>
</template>

<script>
  import {abstractField} from 'vue-form-generator'
  import {isValidTime} from '@hc/graphql'
  export default {
    mixins: [ abstractField ],
    data() {
      return {
        isValid: true,
        time: null
      }
    },
    watch: {'model.id': 'onIdChanged'},
    methods: {
      onFieldChanged($event) {
        const time = $event.target.value
        if (time === '') {
          this.value = null
          this.isValid = true
          return
        }
        this.isValid = isValidTime(time)
        if (!this.isValid) return this.time = time
        this.value = time
      },
      onTimePicked(input) {
        const time = this.getFormattedTime(input)
        this.isValid = isValidTime(time)
        this.value = time
      },
      onIdChanged() {
        if (this.model[this.schema.model]) {
          const time = this.getFormattedTime(this.model[this.schema.model])
          this.isValid = isValidTime(time)
          this.value = this.model[this.schema.model]
        }
      },
      getFormattedTime(time) {
        const [hours, minutes] = time.split(':')
        return `${hours}:${minutes}`
      }
    },
    computed: {
      formattedValue() {
        if (this.value) {
          return this.getFormattedTime(this.value)
        }
      }
    }
  }
</script>
